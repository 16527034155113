<template>
  <v-container>
    <div id="activate" style="height: 100%">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="6" md="6" lg="5">
          <v-card elevation="12" style="margin-top: 5vh">
            <v-progress-linear
              :active="!loaded"
              indeterminate
              absolute
              top
            ></v-progress-linear>

            <!-- Alert -->
            <v-alert
              v-if="!user && loaded"
              border="left"
              icon="fa-exclamation-triangle"
              type="warning"
              outlined
              ><strong>Aktiveringslinket er ikke længere gyldigt</strong
              ><br />Brugeren er muligvis allerede aktiveret.<br /><br />
              <a href="/#/login" class="orange--text"> Go to login screen </a
              ><br />
            </v-alert>

            <v-card-text v-if="user" style="padding: 40px 40px 40px 40px">
              <!-- Logo -->
              <div class="text-center mb-4">
                <img
                  src="../assets/logo.png"
                  alt="Bryggeriforeningen"
                  style="width: 240px; height: auto"
                />
              </div>

              <!-- Activation form -->
              <div v-if="!user.activated">
                <div class="text-center">
                  <h2 class="mb-2">{{ user.email }}</h2>
                  <h3 class="mb-8">{{ user.company.name }}</h3>
                  <p>Indtast dit fulde navn og vælg en adgangskode.</p>
                </div>
                <v-form
                  ref="form"
                  v-model="valid"
                  @submit.prevent="onSubmit"
                  lazy-validation
                >
                  <v-text-field
                    label="Fulde navn"
                    name="name"
                    type="text"
                    required
                    :rules="[requiredRule]"
                    v-model="form.name"
                  ></v-text-field>

                  <div class="mt-4">
                    Adgangskoden skal bestå af følgende:
                    <ul class="mt-3 mb-3">
                      <li>Minimumslængde på 8 karakterer</li>
                    </ul>
                    Adgangskoden skal have 3 ud af 4:
                    <ul class="mt-3">
                      <li>Mindst et stort bogstav [A-Z]</li>
                      <li>Mindst et lille bogstav [a-a]</li>
                      <li>Mindst et tal [0-9]</li>
                      <li>Mindst en specialkarakter [-$+# etc.]</li>
                    </ul>
                  </div>

                  <v-text-field
                    label="Ny adgangskode"
                    name="password"
                    type="password"
                    required
                    :rules="[requiredRule, lengthEightRule, passwordRule]"
                    v-model="form.password"
                    @input="validate"
                  ></v-text-field>
                  <v-text-field
                    label="Gentag ny adgangskode"
                    name="repeatpassword"
                    type="password"
                    required
                    :rules="[requiredRule, passwordSameRule]"
                    v-model="form.repeatpassword"
                  ></v-text-field>
                  <v-checkbox
                    v-model="form.terms"
                    class="mb-3"
                    required
                    :rules="[requiredRule]"
                  >
                    <template v-slot:label>
                      <div>
                        Jeg har læst og er enig i<br />
                        <a @click.stop.prevent="onPrivacyClick">
                          betingelser og vilkår
                        </a>
                      </div>
                    </template>
                  </v-checkbox>
                  <v-btn
                    type="submit"
                    large
                    class="primary"
                    min-width="200"
                    block
                    >Aktiver</v-btn
                  >
                </v-form>
              </div>

              <!-- Activated -->
              <div v-if="user.activated">
                <div class="text-center mb-6">
                  <p>Din bruger er nu aktiveret</p>
                </div>
                <v-btn
                  class="mt-6"
                  large
                  min-width="200"
                  block
                  @click="onProceedClick"
                  >Fortsæt til log ind</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import api from "../api/api.js";
import {
  required,
  lengthEight,
  hasUpper,
  hasLower,
  hasNumber,
  hasSpecialCharacter,
} from "../utils/FormRules.js";
export default {
  name: "activate",
  components: {},
  data: () => ({
    valid: false,
    loaded: false,
    activated: false,
    user: null,
    form: {
      name: "",
      password: "",
      repeatpassword: "",
    },
    requiredRule: required,
    lengthEightRule: lengthEight,
  }),
  mounted() {
    this.loadUser();
  },
  methods: {
    ...mapActions(["systemMessage"]),
    async loadUser() {
      try {
        const result = await api.inactiveUser(
          this.$route.query.email,
          this.$route.query.token
        );
        this.user = result.data;
        this.loaded = true;
      } catch (error) {
        this.loaded = true;
      }
    },
    passwordRule(v) {
      // Test rules individually
      const hasValidUpper = hasUpper(v);
      const hasValidLower = hasLower(v);
      const hasValidNumber = hasNumber(v);
      const hasValidSpecial = hasSpecialCharacter(v);

      // Collects rule results
      const results = [
        hasValidUpper,
        hasValidLower,
        hasValidNumber,
        hasValidSpecial,
      ];

      // Filter out invalid rule results
      const invalids = results.filter((result) => result !== true);

      // Allow for one individual rule to be invalid, for the main rule to be valid
      return invalids.length <= 1
        ? true
        : `Must follow ${invalids.length - 1} more of the rules above`;
    },
    passwordSameRule(v) {
      return v === this.form.password || "Repeat password must match password";
    },
    async onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        try {
          const result = await api.activateUser(
            this.$route.query.email,
            this.$route.query.token,
            this.form.name,
            this.form.password
          );

          this.user = result.data;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onProceedClick() {
      window.location.href = "/#/login";
    },
    validate() {
      this.$refs.form.validate();
    },
    onPrivacyClick() {
      window.open(
        "https://bryggeriforeningen.dk/wp-content/uploads/2022/05/Vilkaar-cookies-og-persondatapolitik-2022.pdf",
        "_blank"
      );
    },
  },
  computed: {
    identicalRule() {
      return () =>
        this.form.password === this.form.repeatpassword ||
        "Passwords must be identical";
    },
  },
};
</script>
